<template>
    <div class="machine-create-edit">
        <a-modal
            :visible="show"
            title="新建/编辑设备"
            on-ok="handleSubmit"
            @cancel="handleCancel"
        >
            <template slot="footer">
                <a-button :loading="loading" @click="handleSubmit"
                    >确定</a-button
                >
                <a-button key="back" @click="handleCancel">关闭</a-button>
            </template>

            <a-form v-bind="formItemLayout" :form="form" @submit="handleSubmit">
                <a-form-item label="设备唯一编码">
                    <a-input
                        v-decorator="validate.sn"
                        placeholder="填写设备唯一编码"
                    />
                </a-form-item>

                <a-form-item label="设备名称">
                    <a-input
                        v-decorator="validate.machineName"
                        placeholder="填写设备名称以便记忆"
                    />
                </a-form-item>

                <!-- 新建/编辑显示 -->
                <template v-if="bindOrEdit == 1">
                    <a-form-item label="柜体类型">
                        <a-radio-group v-decorator="validate.machineType">
                            <a-radio value="0"> 单门柜 </a-radio>
                            <a-radio value="1"> 双门柜 </a-radio>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item label="识别类型">
                        <a-radio-group v-decorator="validate.identifyType">
                            <a-radio value="0"> 货道识别 </a-radio>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item label="货道数量">
                        <a-input
                            v-decorator="validate.channelCount"
                            placeholder="请输入货道数量（层）"
                        />
                    </a-form-item>
                </template>

                <a-form-item label="归属商家">
                    <a-select
                        v-decorator="validate.business"
                        @select="selectBusiness"
                    >
                        <template v-for="item in business">
                            <a-select-option :value="item.id" :key="item.id">
                                {{ item.name }}
                            </a-select-option>
                        </template>
                    </a-select>
                </a-form-item>

                <a-form-item label="归属门店">
                    <a-select v-decorator="validate.store">
                        <template v-for="item in store">
                            <a-select-option :value="item.id" :key="item.id">
                                {{ item.name }}
                            </a-select-option>
                        </template>
                    </a-select>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
import { createMachine, editMachine, getMachineDetail } from "network/machine";
import { getUsersList } from "network/usersManage";
import { getStoreList } from "network/store";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("machineManage");

export default {
    name: "MachineCreateEdit", // 设备创建/编辑
    props: {
        show: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,

            form: this.$form.createForm(this, { name: "machineCreateEdit" }),

            formItemLayout: {
                labelCol: { span: 7 },
                wrapperCol: { span: 13 },
            },

            machineNo: '', // 设备唯一编号

            business: [
                {
                    name: "请选择商家",
                    id: "",
                },
            ],

            store: [
                {
                    name: "请选择门店",
                    id: "",
                },
            ],

            validate: {
                sn: [
                    // 设备编码
                    "sn",
                    {
                        rules: [
                            {
                                required: true,
                                message: "填写设备唯一编码",
                                pattern: /^[A-Za-z0-9]+$/,
                            },
                        ],
                    },
                ],
                machineName: ["machineName"], // 设备名称
                machineType: [
                    // 设备类型
                    "machineType",
                    {
                        initialValue: "0",
                        rules: [
                            {
                                required: true,
                                message: "请选择柜体类型",
                            },
                        ],
                    },
                ],
                identifyType: [
                    // 识别类型
                    "identifyType",
                    {
                        initialValue: "0",
                        rules: [
                            {
                                required: true,
                                message: "请选择识别类型",
                            },
                        ],
                    },
                ],
                channelCount: [
                    // 货道数量
                    "channelCount",
                    {
                        rules: [
                            {
                                required: true,
                                message: "请输入货道数量（层）",
                            },
                        ],
                    },
                ],
                business: ["business", { initialValue: "" }],
                store: ["store", { initialValue: "" }],
            },
        };
    },

    computed: {
        ...mapGetters(["editMachineId", "bindOrEdit"]),
    },

    watch: {
        editMachineId(newVal) {
            if (newVal && this.show) {
                this._getMachineDetail(newVal);
            }
        },
    },

    created() {
        this._getUsersList(1, 1000);
    },

    methods: {
        // ----------------事件处理函数---------------
        ...mapActions(["setEditMachineIdAsync", 'setBindOrEditAsync']),

        // 选择了商家
        selectBusiness(value) {
            if (value) {
                let params = {
                    userId: value || "",
                };
                this._getStoreList(1, 1000, params);
            } else {
                this.store = [
                    {
                        name: "请选择门店",
                        id: "",
                    },
                ];

                this.validate.store[1].initialValue = "";
                this.form.getFieldDecorator("store", this.validate.store[1]);
            }
        },

        handleCancel() {
            this.form.resetFields();

            this.setEditMachineIdAsync("");
            this.setBindOrEditAsync(1);

            this.$emit("update:show", false);
        },
        handleSubmit(e) {
            e.preventDefault();

            this.loading = true;

            this.form.validateFields((err, values) => {
                    if (!err) {
                    let sn = values.sn,
                        name = values.machineName || "",
                        userId = values.business || "",
                        store = values.store,
                        type = values.machineType || this.validate.machineType[1].initialValue,
                        layers = values.channelCount || this.validate.channelCount[1].initialValue,
                        identificationType = values.identifyType || this.validate.identifyType[1].initialValue;


                    if (this.editMachineId) {
                        let no = this.machineNo;
                        this._editMachine(sn, name, userId, store, no, type, layers, identificationType);

                        return;
                    }

                    this._createMachine(sn, name, userId, store, type, layers, identificationType);
                } else {
                    this.loading = false;
                }
            });
        },

        // -----------------网络请求-----------------
        // 新建设备
        _createMachine(sn, name = "", userId = "", storeId = "", type, layers, identificationType) {
            createMachine(sn, name, userId, storeId, type, layers, identificationType)
                .then((res) => {
                    this.$message.success(res.message);

                    this.$emit("onSuccess");
                    this.handleCancel();
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        // 编辑设备
        _editMachine(sn, name = "", userId = "", storeId = "", no, type, layers, identificationType) {
            editMachine(sn, name, userId, storeId, no, type, layers, identificationType)
                .then((res) => {
                    this.$message.success(res.message);

                    this.$emit("onSuccess");
                    this.handleCancel();
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },

        // 获取设备详情
        _getMachineDetail(id) {
            getMachineDetail(id)
                .then((res) => {
                    const data = res.data;

                    this.machineNo = data.no;

                    this.form.setFieldsValue({ sn: data.sn });
                    this.form.setFieldsValue({ machineName: data.name });

                    this.validate.business[1].initialValue = String(
                        data.userId || ""
                    );
                    this.form.getFieldDecorator(
                        "business",
                        this.validate.business[1]
                    );

                    if (data.userId) {
                        let params = {
                            userId: data.userId || "",
                        };
                        this._getStoreList(1, 1000, params);
                    }

                    this.validate.store[1].initialValue = String(
                        data.storeId || ""
                    );
                    this.form.getFieldDecorator(
                        "store",
                        this.validate.store[1]
                    );

                    // 设备类型
                    this.validate.machineType[1].initialValue = String(
                        data.type
                    );
                    this.form.getFieldDecorator(
                        "machineType",
                        this.validate.machineType[1]
                    );

                    // 识别类型
                    this.validate.identifyType[1].initialValue = String(
                        data.identificationType
                    );
                    this.form.getFieldDecorator(
                        "identifyType",
                        this.validate.identifyType[1]
                    );

                    // 货道数量
                    if (this.bindOrEdit === 1) {
                        this.form.setFieldsValue({ channelCount: String(data.layers) });
                    } else {
                        this.validate.channelCount[1].initialValue = String(
                            data.layers
                        );
                        this.form.getFieldDecorator(
                            "channelCount",
                            this.validate.channelCount[1]
                        );
                    }
                })
                .catch((err) => {});
        },

        // 获取用户列表
        _getUsersList(
            pageNumber,
            pageSize,
            params = {
                userType: "1",
            }
        ) {
            getUsersList(pageNumber, pageSize, params)
                .then((res) => {
                    const list = res.data;

                    class User {
                        constructor(name, id) {
                            this.name = name;
                            this.id = id;
                        }
                    }

                    for (let i = 0; i < list.length; i++) {
                        let item = list[i];
                        let user = new User(item.operatorName, item.id);
                        this.business.push(user);
                    }
                })
                .catch((err) => {});
        },

        // 获取门店列表
        _getStoreList(pageNumber, pageSize, params = { userId: "" }) {
            getStoreList(pageNumber, pageSize, params)
                .then((res) => {
                    const list = res.data;

                    class Store {
                        constructor(name, id) {
                            this.name = name;
                            this.id = id;
                        }
                    }

                    let newList = [
                        {
                            name: "请选择门店",
                            id: "",
                        },
                    ];

                    for (let i = 0; i < list.length; i++) {
                        let item = list[i];
                        let store = new Store(item.name, item.id);
                        newList.push(store);
                    }

                    this.store = newList;
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="less" scoped>
</style>