<template>
    <div class="machine-btns">
        <a-button class="btn" type="primary" @click="handleShowModal">新建设备</a-button>
    </div>
</template>

<script>
export default {
    name: "MachineBtns", // 设备管理操作按钮
    methods: {
        handleShowModal() {
            this.$emit('onShowModal')
        }
    },
};
</script>

<style lang="less" scoped>
.machine-btns {
    width: 100%;
    margin: 20px auto;

    .btn {
        margin-right: 20px;
    }
}
</style>