<template>
    <div id="machine-manage">
        <!-- 顶部面包屑 -->
        <breadcrumb :routes="breadcrumb" />

        <!-- 数据内容 -->
        <div class="machine-data">
            <!-- 筛选 -->
            <machine-search @onSearch="onSearch" />

            <!-- 操作按钮 -->
            <machine-btns @onShowModal="showModal = true" />

            <!-- 列表 -->
            <div class="machine-list-wrapper">
                <machine-list :list="machineList" :loading="loading" @onEdit="showModal = true" />
            </div>

            <!-- 分页 -->
            <pagination
                :page.sync="page"
                :page-size="pageSize"
                :total-size="totalSize"
                @onPageChange="onPageChange"
                @onSizeChange="onSizeChange"
            />
        </div>

        <!-- 新建/编辑 -->
        <machine-create-edit :show.sync="showModal" @onSuccess="onSuccess" />
    </div>
</template>

<script>
import MachineSearch from "./childComps/MachineSearch";
import MachineList from "./childComps/MachineList";
import MachineBtns from "./childComps/MachineBtns";
import MachineCreateEdit from "./childComps/MachineCreateEdit";

import Breadcrumb from "components/breadcrumb/Breadcrumb";
import Pagination from "components/pagination/Pagination";

import { getMachineList } from "network/machine";

export default {
    name: "MachineManage", // 设备管理
    components: {
        MachineSearch,
        MachineList,
        MachineBtns,
        MachineCreateEdit,

        Breadcrumb,
        Pagination,
    },
    data() {
        return {
            breadcrumb: [
                {
                    path: '',
                    breadcrumbName: "首页",
                },
                {
                    path: "/machineManage",
                    breadcrumbName: "设备管理",
                },
            ],

            machineName: "", // 设备名称
            machineNo: "", // 设备编号
            status: 0, // 绑定状态
            machineStatus: '', // 设备状态

            page: 1,
            pageSize: 10,
            totalSize: 0,
            loading: false,

            machineList: [],

            showModal: false, // 是否显示创建/编辑弹窗
        };
    },
    created() {
        this._getMachineList(this.page, this.pageSize);
    },
    methods: {
        // -------------------事件处理函数-------------------
        // 查询
        onSearch(values) {
            this.machineNo = values.machineId || "";
            this.machineName = values.name || "";
            this.status = values.status || '';
            this.machineStatus = values.machineStatus || '';

            let params = {
                name: this.machineName,
                no: this.machineNo,
                status: this.machineStatus
            };

            if (this.status > 0) {
                params.binding = this.status
            }

            this.page = 1;

            this._getMachineList(this.page, this.pageSize, params);
        },
        // 切换页码
        onPageChange(page) {
            this.page = page;

            let params = {
                name: this.machineName,
                no: this.machineNo,
                status: this.machineStatus
            };

            if (this.status > 0) {
                params.binding = this.status
            }

            this._getMachineList(this.page, this.pageSize, params);
        },

        // 切换一页多少条
        onSizeChange(pageSize) {
            this.pageSize = pageSize;

            let params = {
                name: this.machineName,
                no: this.machineNo,
                status: this.machineStatus
            };

            if (this.status > 0) {
                params.binding = this.status
            }

            this._getMachineList(this.page, this.pageSize, params);
        },

        // 新建/编辑成功
        onSuccess() {
            let params = {
                name: this.machineName,
                no: this.machineNo,
                status: this.machineStatus
            };

            if (this.status > 0) {
                params.binding = this.status
            }

            this._getMachineList(this.page, this.pageSize, params);
        },

        // ---------------------网络请求-------------------
        // 获取设备列表
        _getMachineList(
            pageNumber,
            pageSize,
            params = {
                name: "",
                no: "",
                binding: "",
            }
        ) {
            this.loading = true;

            getMachineList(pageNumber, pageSize, params)
                .then((res) => {
                    this.totalSize = res.totalSize;
                    this.page = res.currentSize > 0 ? pageNumber : this.page;

                    const list = res.data;

                    let newList = [];

                    class Machine {
                        constructor(obj) {
                            for (const key in obj) {
                                this[key] = obj[key];
                            }
                        }
                    }

                    for (let i = 0; i < list.length; i++) {
                        let item = list[i];
                        let machine = new Machine({
                            machineType: item.type == 1 ? "双门柜" : "单门柜",
                            machineStatus: item.status == 1 ? "在线" : "离线",
                            address: ((item.storeRegionName || '') + (item.storeAddress || '')) || '无',
                            bind: item.userRealName || "未绑定",
                            nowVersion: item.hardwareVersion || '无',
                            newVersion: item.lastHardwareVersion || '无',
                            ...item,
                        });

                        for (const key in machine) {
                            !machine[key] &&
                                machine[key] != 0 &&
                                (machine[key] = "暂无数据");
                        }

                        newList.push(machine);
                    }

                    this.loading = false;
                    this.machineList = newList;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style lang="less" scoped>
#machine-manage {
    width: 100%;
    // height: 100%;
    position: relative;
    overflow: auto;
    // display: flex;
    // flex-direction: column;

    .machine-data {
        margin: 0 20px 20px;
        padding: 20px;
        background-color: #fff;
        border-radius: 5px;
        // display: flex;
        // flex-direction: column;
        overflow: auto;

        .machine-list-wrapper {
            // flex: 1;
            width: 100%;
            margin: 20px auto;
            overflow: hidden;
        }
    }
}
</style>