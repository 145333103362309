<template>
    <div class="machine-list" ref="listBox">
        <a-table
            :columns="columns"
            :data-source="list"
            :pagination="false"
            :loading="loading"
            :scroll="scroll"
            :row-key="record => record.id"
        >
            <div slot="action" slot-scope="record">
                <a-button class="btn" type="primary" @click="handleEdit(record)"
                    >编辑</a-button
                >

                <a-popconfirm title="是否启用？" @confirm="onEnable(record)">
                    <a-button class="btn" type="primary">绑定</a-button>
                </a-popconfirm>

                <a-popconfirm title="是否停用？" @confirm="onStop(record)">
                    <a-button type="danger">解绑</a-button>
                </a-popconfirm>
            </div>
        </a-table>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('machineManage');

export default {
    name: "MachineList", // 设备列表
    props: {
        list: {
            type: Array,
            default() {
                return [];
            },
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            columns: [
                {
                    title: "盒子唯一码",
                    dataIndex: "sn",
                    key: "sn",
                    width: 160,
                    fixed: "left",
                },
                {
                    title: "炳联码",
                    dataIndex: "no",
                    key: "no",
                    width: 160,
                    fixed: "left",
                },
                {
                    title: "设备名称",
                    dataIndex: "name",
                    key: "name",
                    width: 130,
                },
                {
                    title: "设备类型",
                    dataIndex: "machineType",
                    key: "machineType",
                    width: 130,
                },
                {
                    title: "状态",
                    dataIndex: "machineStatus",
                    key: "machineStatus",
                    width: 130,
                },
                {
                    title: "设备门店地址",
                    dataIndex: "address",
                    key: "address",
                    width: 200,
                },
                {
                    title: "商家绑定",
                    dataIndex: "bind",
                    key: "bind",
                    width: 130,
                },
                {
                    title: "创建时间",
                    dataIndex: "createTime",
                    key: "createTime",
                    width: 180,
                },
                {
                    title: "当前版本",
                    dataIndex: "nowVersion",
                    key: "nowVersion",
                    width: 130,
                },
                {
                    title: "最新版本",
                    dataIndex: "newVersion",
                    key: "newVersion",
                    width: 130,
                },
                {
                    title: "操作",
                    key: "operation",
                    fixed: "right",
                    width: 300,
                    align: "center",
                    scopedSlots: { customRender: "action" },
                },
            ],
            scroll: {
                x: true,
                y: 480,
            },
        };
    },
    methods: {
        // -----------------事件处理函数----------------
        ...mapActions([ 'setEditMachineIdAsync', 'setBindOrEditAsync' ]),

        // 编辑
        handleEdit(value) {
            this.setEditMachineIdAsync(value.id)
            this.setBindOrEditAsync(1)
            this.$emit("onEdit");
        },

        // 绑定
        onEnable(value) {
            this.setEditMachineIdAsync(value.id)
            this.setBindOrEditAsync(2)
            this.$emit("onEdit");
        },

        // 解绑
        onStop(value) {
            this.setEditMachineIdAsync(value.id)
            this.setBindOrEditAsync(2)
            this.$emit("onEdit");
        }
    },
};
</script>

<style lang="less" scoped>
.machine-list {
    width: 100%;
    // height: 650px;
    overflow: auto;

    .btn {
        margin-right: 20px;
    }
}
</style>