import api from './api'
import { request } from './request'

// 获取设备列表
function getMachineList(pageNumber, pageSize, params = {
    keyword: '', // 关键字（编号、名称）
    location: '', // 位置 0单门 1双门左 2双门右
    status: '', // 是否在线 0否 1是
    storeId: '', // 门店ID
    name: '',
    sn: '',
    no: '',
    binding: ''
}) {
    return request({
        url: api.machine.list,
        method: 'post',
        data: { pageNumber, pageSize, params }
    })
}

// 新建设备
function createMachine(sn, name, userId, storeId, type, layers, identificationType) {
    return request({
        url: api.machine.create,
        method: 'post',
        data: { sn, name, userId, storeId, type, layers, identificationType }
    })
}

// 获取设备详情
function getMachineDetail(id) {
    return request({
        url: api.machine.detail + `/${ id }`,
        method: 'get'
    })
}

// 编辑设备
function editMachine(sn, name, userId, storeId, no, type, layers, identificationType) {
    return request({
        url: api.machine.edit,
        method: 'post',
        data: { sn, name, userId, storeId, no, type, layers, identificationType }
    })
}

export {
    getMachineList,
    createMachine,
    getMachineDetail,
    editMachine
}