<template>
    <div class="machine-search-wrapper">
        <a-form class="search-wrapper"
                layout="inline"
                :form="form"
                @submit="handleSearch">

            <!-- 设备ID -->
            <a-form-item label="设备ID"
                         class="form-item-wrapper"
                         :label-col="formName.labelCol"
                         :wrapper-col="formName.wrapperCol">

                <a-input v-decorator="['machineId', { initialValue: '' }]"
                         placeholder="按设备ID搜索" />

            </a-form-item>

            <!-- 设备名称 -->
            <a-form-item label="设备名称"
                         class="form-item-wrapper"
                         :label-col="formName.labelCol"
                         :wrapper-col="formName.wrapperCol">

                <a-input v-decorator="['name', { initialValue: '' }]"
                         placeholder="按设备名称搜索" />

            </a-form-item>

            <!-- 绑定状态 -->
            <a-form-item label="绑定状态"
                         class="form-item-wrapper status-form"
                         :label-col="formStatus.labelCol"
                         :wrapper-col="formStatus.wrapperCol">

                <a-select v-decorator="['status', { initialValue: '0' }]">

                    <template v-for="item in status">
                        <a-select-option :value="item.value"
                                         :key="item.value">
                            {{ item.name }}
                        </a-select-option>
                    </template>

                </a-select>

            </a-form-item>

            <!-- 设备状态 -->
            <a-form-item label="设备状态"
                         class="form-item-wrapper status-form"
                         :label-col="formStatus.labelCol"
                         :wrapper-col="formStatus.wrapperCol">

                <a-select v-decorator="['machineStatus', { initialValue: '' }]">

                    <template v-for="item in machineStatus">
                        <a-select-option :value="item.value"
                                         :key="item.value">
                            {{ item.name }}
                        </a-select-option>
                    </template>

                </a-select>

            </a-form-item>

            <!-- 查询/重置 -->
            <a-form-item class="form-item-wrapper">
                <a-button class="submit-btn"
                          type="primary"
                          html-type="submit">
                    查询
                </a-button>

                <a-button @click="resetForm">
                    重置
                </a-button>
            </a-form-item>

        </a-form>
    </div>
</template>

<script>
export default {
    name: 'MachineSearch', // 设备筛选
    data () {
        return {
            formName: {
                labelCol: { span: 7 },
                wrapperCol: { span: 14 },
            },
            formStatus: {
                labelCol: { span: 7 },
                wrapperCol: { span: 14 },
            },
            form: this.$form.createForm(this, { name: 'machineSearch' }),

            status: [
                {
                    name: '全部',
                    value: '0'
                },
                {
                    name: '未绑定',
                    value: '1'
                },
                {
                    name: '已绑定',
                    value: '2'
                }
            ],

            machineStatus: [
                {
                    name: '全部',
                    value: ''
                },
                {
                    name: '离线',
                    value: '0'
                },
                {
                    name: '在线',
                    value: '1'
                }
            ]
        }
    },
    methods: {
        // 点击查询
        handleSearch (e) {
            e.preventDefault();
            const values = this.form.getFieldsValue();
            
            this.$emit('onSearch', values)
        },
        // 重置
        resetForm() {
            this.form.resetFields()

            const values = this.form.getFieldsValue();
            
            this.$emit('onSearch', values)
        }
    }
}
</script>

<style lang="less" scoped>
.machine-search-wrapper {
    width: 100%;

    .search-wrapper {
        width: 100%;

        .form-item-wrapper {
            width: 300px;

            .submit-btn {
                margin-right: 20px;
            }
        }
    }
}
</style>